import { useRef, useEffect, useState } from 'react'

export const LOADING = 'loading'
export const SUCCESS = 'success'
export const ERROR = 'error'
export const IDLE = 'idle'

export function useGetData (request, payload, options = {}, dependencies = []) {
  const dataRef = useRef({
    cancel: undefined,
    settled: false
  })
  const { isDisabled, payloadNotChange } = options
  let [{ status, data, error, statusCode }, setResult] = useState({
    status: isDisabled ? IDLE : LOADING,
    data: null,
    error: null
  })
  if (data === '' && statusCode === 200) {
    data = null
    statusCode = 404
  }
  const [reloadKey, setReloadKey] = useState()
  const cancelGetData = () => {
    const { cancel, settled } = dataRef.current
    if (!settled && cancel) {
      cancel()
    }
  }
  const payloadDepend = payloadNotChange ? [] : [payload]
  useEffect(async () => {
    const outerStatus = status
    dataRef.current.settled = false
    dataRef.current.cancel = undefined

    if (!isDisabled) {
      if (outerStatus !== LOADING) {
        setResult({ status: LOADING, data })
      }
      const promise = request(payload)
      dataRef.current.cancel = promise.cancel
      promise.finally(() => {
        dataRef.current.settled = true
      })
      const { data: responseData, status } = await promise
      if (status === 200 || status === 204) {
        setResult({
          data: responseData,
          status: SUCCESS,
          error: null,
          statusCode: status
        })
      } else if (status >= 400 && status <= 511) {
        setResult({
          data: null,
          status: ERROR,
          error: data,
          statusCode: status
        })
      }
    } else if (isDisabled && status !== IDLE) {
      setResult({ status: IDLE, data, error: null })
    }
    return cancelGetData
  }, [...payloadDepend, reloadKey, isDisabled, ...dependencies])

  useEffect(() => {
    return cancelGetData
  }, [])

  function reload () {
    setReloadKey(Date.now())
  }
  return { status, error, data, statusCode, reload }
}

export function useSimpleDeduplicateGetData (
  key,
  store,
  request,
  payload,
  options = {},
  dependencies = []
) {
  const dataRef = useRef({
    cancel: undefined,
    settled: false
  })

  if (!store) {
    options.isDisabled = true
  }

  const cancelGetData = () => {
    const { cancel, settled } = dataRef.current
    if (!settled && cancel) {
      if (store) {
        store[key] = null
      }
      cancel()
    }
  }

  const [{ status, data, error, statusCode }, setResult] = useState({
    status: options?.isDisabled ? IDLE : LOADING,
    data: null,
    error: null
  })
  const [reloadKey, setReloadKey] = useState()

  useEffect(async () => {
    dataRef.current.settled = false
    dataRef.current.cancel = undefined

    if (!options || !options.isDisabled) {
      let promise
      if (store[key]) {
        promise = store[key]
      } else {
        promise = request(payload)
        promise.finally(() => {
          dataRef.current.settled = true
        })
        dataRef.current.cancel = promise.cancel
        store[key] = promise
      }
      const { data: responseData, status } = await promise
      if (status === 200) {
        setResult({
          data: responseData,
          status: SUCCESS,
          error: null,
          statusCode: status
        })
      } else if (status >= 400 && status <= 511) {
        // request might be canceled, status --> undefined
        setResult({
          data: null,
          status: ERROR,
          error: data,
          statusCode: status
        })
      }
    } else if (options?.isDisabled && status !== IDLE) {
      setResult({ status: IDLE, data, error: null })
    }
    return cancelGetData
  }, [payload, reloadKey, options?.isDisabled, store, ...dependencies])

  useEffect(() => {
    return cancelGetData
  }, [])

  function reload () {
    if (store[key]) store[key] = null
    if (store.data[key]) store.data[key] = null
    setReloadKey(Date.now())
  }
  store.data[key] = data
  return { status, error, data, statusCode, reload }
}
